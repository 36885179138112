import { ThemeConfig } from 'antd';

export const AntThemeConfig: ThemeConfig = {
  token: {
    colorPrimary: '--primary-color',
    colorSuccess: '#79c04a',
    colorBgBase: '#fff',
    fontFamily: 'Figtree',
    borderRadius: 5,
  },
  components: {
    Menu: {
      itemHoverBg: 'var(--primary-color)',
      itemSelectedBg: 'var(--primary-color)',
      itemSelectedColor: 'var(--color-black)',
      itemActiveBg: 'var(--primary-color)',
    },
    Select: {
      optionSelectedColor: 'var(--primary-color)',
    },
    Table: {
      headerBg: 'var(--primary-color)',
      fontWeightStrong: 400,
    },
  },
};
