import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';
import cn from 'classnames';

import { LogoLightIcon } from '@/assets/icons';
import typography from '@/assets/styles/typography.module.css';
import { RoutesEnum } from '@/enums';

import styles from './404.module.css';

export const Error404 = () => {
  const navigate = useNavigate();

  const handleGoToClaims = () => {
    navigate(RoutesEnum.CLAIMS);
  };

  return (
    <div className={styles.layout}>
      <LogoLightIcon className={styles.logoIcon} />
      <h1 className={cn(styles.title, typography.h1)}>404</h1>
      <p className={cn(styles.description, typography.paragraphMRegular)}>
        The page you are looking for does not exist.
      </p>
      <Button
        type="primary"
        onClick={handleGoToClaims}
        className={styles.button}
      >
        Go to Claims page
      </Button>
    </div>
  );
};
