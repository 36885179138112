import { FC } from 'react';
import Lottie from 'react-lottie';

import loaderAnimation from '@/assets/loader.json';

export interface LoaderProps {
  width?: number;
  height?: number;
}

export const Loader: FC<LoaderProps> = ({ width = 200, height = 200 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <Lottie options={defaultOptions} width={width} height={height} />;
};

export const LoaderScreen: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'var(--primary-color-light)',
      }}
    >
      <Loader width={250} height={250} />
    </div>
  );
};
