export const RoutesEnum = {
  LOGIN: '/login',
  LOGIN_EMAIL: '/login/email',
  LOGIN_EMAIL_VERIFY: '/login/email-verify',
  LOGIN_PASSWORD: '/login/password',

  SIGN_IN: '/sign-in',
  SET_PASSWORD: '/set-password',
  RESET_PASSWORD: '/reset-password',

  SALES_DASHBOARD: '/',
  SALES: '/sales',
  REPORTS: '/reports',
  INVOICES: '/invoices',

  CLAIMS: '/claims',
  CLAIM: '/claims/:id',
  CLAIM_DOCUMENT_VIEW: '/claims/:id/document/:documentId',

  TRANSFER_OWNERSHIP: '/transfer-ownership',
  TRANSFER_OWNERSHIP_DETAIL: '/transfer-ownership/:id',
  TRANSFER_OWNERSHIP_DOCUMENT_VIEW:
    '/transfer-ownership/:id/document/:documentId',
};
