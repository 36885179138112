export enum ClaimStatus {
  CLAIM_STATUS_DEFAULT = 'CLAIM_STATUS_DEFAULT',
  CLAIM_STATUS_DRAFT = 'CLAIM_STATUS_DRAFT',
  CLAIM_STATUS_ASSESSMENT = 'CLAIM_STATUS_ASSESSMENT',
  CLAIM_STATUS_APPROVED = 'CLAIM_STATUS_APPROVED',
  CLAIM_STATUS_DECLINED = 'CLAIM_STATUS_DECLINED',
  CLAIM_STATUS_DECLINED_BRAND_APPROVED = 'CLAIM_STATUS_DECLINED_BRAND_APPROVED',
  CLAIM_STATUS_DECLINED_BRAND_DECLINED = 'CLAIM_STATUS_DECLINED_BRAND_DECLINED',
  CLAIM_STATUS_APPROVED_REPLACEMENT_ITEM_REGISTERED = 'CLAIM_STATUS_APPROVED_REPLACEMENT_ITEM_REGISTERED',
  CLAIM_STATUS_DECLINED_BRAND_APPROVED_REPLACEMENT_ITEM_REGISTERED = 'CLAIM_STATUS_DECLINED_BRAND_APPROVED_REPLACEMENT_ITEM_REGISTERED',
}

export enum ClaimDecision {
  CLAIM_STATUS_APPROVED = 'CLAIM_STATUS_APPROVED',
  CLAIM_STATUS_DECLINED = 'CLAIM_STATUS_DECLINED',
}

export const claimStatusTitles: Record<keyof typeof ClaimStatus, string> = {
  CLAIM_STATUS_DEFAULT: 'Default',
  CLAIM_STATUS_DRAFT: 'Draft',
  CLAIM_STATUS_ASSESSMENT: 'Assessment',
  CLAIM_STATUS_APPROVED: 'Approved',
  CLAIM_STATUS_DECLINED: 'Declined',
  CLAIM_STATUS_DECLINED_BRAND_APPROVED: 'Brand approved',
  CLAIM_STATUS_DECLINED_BRAND_DECLINED: 'Brand declined',
  CLAIM_STATUS_APPROVED_REPLACEMENT_ITEM_REGISTERED:
    'Replacement item registered',
  CLAIM_STATUS_DECLINED_BRAND_APPROVED_REPLACEMENT_ITEM_REGISTERED:
    'Brand approved replacement item registered',
};

export const claimStatusesWithBrandDecision = [
  'CLAIM_STATUS_DECLINED_BRAND_DECLINED',
  'CLAIM_STATUS_APPROVED_REPLACEMENT_ITEM_REGISTERED',
  'CLAIM_STATUS_DECLINED_BRAND_APPROVED_REPLACEMENT_ITEM_REGISTERED',
];
