import { FC, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { LoaderScreen } from '@/components/Loader/Loader';
import { RoutesEnum } from '@/enums';
import { CookiesEnum } from '@/enums/storage';
import { getCookie } from '@/utils/setCookie';

interface RouteProps {
  component: FC;
  isPrivate?: boolean;
}

export const Route: FC<RouteProps> = ({
  component: Component,
  isPrivate = false,
}) => {
  if (isPrivate && !getCookie(CookiesEnum.SESSION_ID)) {
    return <Navigate to={RoutesEnum.LOGIN} replace />;
  }

  return (
    <Suspense fallback={<LoaderScreen />}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Component />
      </QueryParamProvider>
    </Suspense>
  );
};
