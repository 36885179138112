import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';
import { ConfigProvider } from 'antd';
import { RecoilEnv, RecoilRoot } from 'recoil';

import { AntThemeConfig } from '@/themes/antTheme';

import '@/themes/theme.css';
import './base.css';
import '@/assets/styles/ant.css';

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ConfigProvider theme={AntThemeConfig}>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  </ConfigProvider>,
);
